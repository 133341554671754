import axios from 'axios';

export default {
    getTagProviders(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/orgs/' + orgId + '/tags/providers');
    },

    getTags(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/orgs/' + orgId + '/tags');
    },

    getTagsForGroup(tenantId, orgId, groupType, groupId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/orgs/' + orgId + '/tags/groups/' + groupType + '/' + groupId);
    },

    addTagToGroup(tenantId, orgId, groupType, groupId, provider, type, providersId, description) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/orgs/' + orgId + '/tags/groups/' + groupType + '/' + groupId, {
            provider,
            type,
            providersId,
            description
        });
    }
}