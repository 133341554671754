<template>
    <div>
         <a-spin v-if="isLoadingTags"></a-spin>

         <div v-else>

            <div v-if="tags.length === 0">
                <a-badge status="default" text="No tags" />
            </div>

            <div class="add-tag-wrapper">
                <a-button @click="openModal" type="primary" size="small">Add Tag</a-button>
            </div>

            <a-modal v-model="showAddTagModal"
                title="Add Tag" @ok="addTag">
                <a-select style="width: 100%"
                size="large"
                v-model="selectedTagToAdd" :options="addableTags" placeholder="Select a tag" />
            </a-modal>

         </div>
    </div>
</template>

<script>
import tags from '../../../../api/tags';

export default {
    props: ['tenantId', 'incidentId', 'orgId'],

    data() {
        return {
            tags: [],
            isLoadingTags: false,

            addableTags: [],
            isLoadingAddableTags: false,

            showAddTagModal: false,

            selectedTagToAdd: null
        }
    },

    created() {
        this.getTags();
    },

    watch: {
        incidentId() {
            this.getTags();
        }
    },

    methods: {
        getTags() {
            this.isLoadingTags = true;
            this.tags = [];
            tags.getTagsForGroup(
                this.tenantId,
                this.orgId,
                'Incident',
                this.incidentId
            ).then((response) => {
                this.tags = response.data;
            }).catch(e => {
                console.log(e);
            }).finally(() => {
                this.isLoadingTags = false;
            });
        },

        openModal() {
            this.loadAddableTags();
            this.selectedTagToAdd = null;
            this.showAddTagModal = true;
        },

        loadAddableTags() {
            this.isLoadingAddableTags = true;
            this.addableTags = [];
            tags.getTags(this.tenantId, this.orgId).then((response) => {
                this.addableTags = response.data;
            }).catch(e => {
                console.log(e);
            }).finally(() => {
                this.isLoadingAddableTags = false;
            });
        },

        addTag() {
            if (!this.selectedTagToAdd) {
                alert('Please select a tag');
                return;
            }
            console.log(this.selectedAddableTag);
        }
    }
}
</script>

<style scoped lang="scss">
.add-tag-wrapper {
    margin-top: 10px;
}
</style>